// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/GeorgeReynolds/Code/on-point/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/Users/GeorgeReynolds/Code/on-point/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/GeorgeReynolds/Code/on-point/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-process-js": () => import("/Users/GeorgeReynolds/Code/on-point/src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-services-js": () => import("/Users/GeorgeReynolds/Code/on-point/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

